<template>
  <div class="frontend test">
    <div
      v-show="$store.state.appConfig.showLoader"
      class="axios-loader"
    >
      <loader />
    </div>
    <div class="p4a__home">
      <header class="p4a__header">
        <b-container>
          <b-row class="auth-inner m-0">
            <!-- Brand logo-->
            <router-link
              :to="'/appointments'"
              :class="isMobile() ? 'mx-auto' : ''"
            >
              <img :src="isMobile() ? logoMobileImg : logoImg">
            </router-link>
            <!-- /Brand logo-->
          </b-row>
        </b-container>
      </header>

      <div>
        <b-container>
          <b-card
            v-if="contactCard"
            class="p4a__contact-card"
            :class="!isMobile() ? 'p4a__contact-card-width' : ''"
          >
            <b-card-text class="mb-0">
              <h3 class="p4a__contact-card__title">
                Book an appointment with us today.
              </h3>
              <p class="p4a__contact-card__sub-title">
                Before we start, may we know if you are...
              </p>
            </b-card-text>
            <b-row
              v-if="!isMobile()"
              class="p4a__contact-service__wrapper match-height"
            >
              <b-col
                sm="4"
                md="4"
                lg="4"
              >
                <b-card
                  class="p4a__contact-sub__card"
                  :class="{ 'p4a__contact-sub__card-active': isNewCustomer, 'p4a__card-opacity': (activityType.prospectBookable == true || isExistingCustomer || isAgent) }"
                >
                  <b-button
                    class="p4a__contact-btn"
                    @click="openBottomForm('new')"
                  >
                    <div class="p4a__contact-sub__card-icon">
                      <b-img
                        :src="personAddIcon"
                        alt="person-add"
                      />
                    </div>
                    <div class="p4a__contact-sub__card-text">
                      <h5>New to Nirvana</h5>
                    </div>
                  </b-button>
                </b-card>
              </b-col>
              <b-col
                sm="4"
                md="4"
                lg="4"
              >
                <b-card
                  class="p4a__contact-sub__card"
                  :class="{ 'p4a__contact-sub__card-active': isExistingCustomer, 'p4a__card-opacity': (isAgent || isNewCustomer) }"
                >
                  <b-button
                    class="p4a__contact-btn"
                    @click="openBottomForm('existing')"
                  >
                    <div class="p4a__contact-sub__card-icon">
                      <b-img
                        :src="patientListIcon"
                        alt="person-add"
                      />
                    </div>
                    <div class="p4a__contact-sub__card-text">
                      <h5>Existing customer</h5>
                    </div>
                  </b-button>
                </b-card>
              </b-col>
              <b-col
                sm="4"
                md="4"
                lg="4"
              >
                <b-card
                  class="p4a__contact-sub__card"
                  :class="{ 'p4a__contact-sub__card-active': isAgent, 'p4a__card-opacity': (isExistingCustomer || isNewCustomer) }"
                >
                  <b-button
                    class="p4a__contact-btn"
                    @click="openBottomForm('agent')"
                  >
                    <div class="p4a__contact-sub__card-icon">
                      <b-img
                        :src="badgeIcon"
                        alt="person-add"
                      />
                    </div>
                    <div class="p4a__contact-sub__card-text">
                      <h5>Agent</h5>
                    </div>
                  </b-button>
                </b-card>
              </b-col>
            </b-row>
            <b-row
              v-if="isMobile()"
              class="p4a__contact-service__wrapper"
            >
              <b-col cols="12">
                <b-card
                  class="p4a__contact-sub__card p4a__contact-sub__card-mobile"
                  :class="{ 'p4a__contact-sub__card-active': isNewCustomer, 'p4a__card-opacity': (activityType.prospectBookable == true || isExistingCustomer || isAgent) }"
                >
                  <b-button
                    class="p4a__contact-mobile__btn"
                    @click="openBottomForm('new')"
                  >
                    <div class="p4a__contact-sub__card-icon">
                      <b-img
                        :src="personAddIcon"
                        alt="person-add"
                      />
                    </div>
                    <div class="p4a__contact-sub__card-text">
                      <h5>New to Nirvana</h5>
                    </div>
                  </b-button>
                </b-card>
              </b-col>
              <b-col cols="12">
                <b-card
                  class="p4a__contact-sub__card p4a__contact-sub__card-mobile"
                  :class="{ 'p4a__contact-sub__card-active': isExistingCustomer, 'p4a__card-opacity': (isAgent || isNewCustomer) }"
                >
                  <b-button
                    class="p4a__contact-mobile__btn"
                    @click="openBottomForm('existing')"
                  >
                    <div class="p4a__contact-sub__card-icon">
                      <b-img
                        :src="patientListIcon"
                        alt="person-add"
                      />
                    </div>
                    <div class="p4a__contact-sub__card-text">
                      <h5>Existing customer</h5>
                    </div>
                  </b-button>
                </b-card>
              </b-col>
              <b-col cols="12">
                <b-card
                  class="p4a__contact-sub__card p4a__contact-sub__card-mobile"
                  :class="{ 'p4a__contact-sub__card-active': isAgent, 'p4a__card-opacity': (isExistingCustomer || isNewCustomer) }"
                >
                  <b-button
                    class="p4a__contact-mobile__btn"
                    @click="openBottomForm('agent')"
                  >
                    <div class="p4a__contact-sub__card-icon">
                      <b-img
                        :src="badgeIcon"
                        alt="person-add"
                      />
                    </div>
                    <div class="p4a__contact-sub__card-text">
                      <h5>Agent</h5>
                    </div>
                  </b-button>
                </b-card>
              </b-col>
            </b-row>
            <div
              v-if="(isNewCustomer || isExistingCustomer) || isAgent"
              class="p4a__contact-form"
            >
              <div
                v-if="isNewCustomer || isExistingCustomer"
                class="p4a__contact-new-customer"
              >
                <p class="p4a__contact-customer__text">
                  Welcome! May we have your contact number to start the booking process?
                </p>
                <b-form @submit.prevent="submitCustomerVerification">
                  <validation-observer
                    ref="frontNewCustomerVerificationForm"
                    #default="{invalid}"
                  >
                    <b-row v-if="!isPhoneHidden">
                      <b-col lg="12">
                        <div class="form-row form-group">
                          <label class="col-md-3 col-form-label p4a__text-bold-gray">Mobile Number *</label>
                          <div class="col">
                            <validation-provider
                              #default="{}"
                              name="Mobile Number"
                              vid="h-phone-number"
                              rules="required|length:12"
                            >
                              <b-form-input
                                id="h-phone-number"
                                v-model="customerContact"
                                class="p4a__activity-input"
                                placeholder="+65 8 Digits Phone Number"
                                name="customerContact"
                                :disabled="existsMultipleCustomer"
                                @input="customerContactValidation = false"
                              />
                              <!-- <cleave
                                id="h-phone-number"
                                v-model="customerContact"
                                class="form-control p4a__activity-input"
                                :raw="false"
                                :options="options.prefix"
                                :disabled="existsMultipleCustomer"
                                @input="customerContactValidation = false"
                              /> -->
                              <!-- <small class="text-danger display-block">{{ errors[0] }}</small> -->
                              <small
                                v-if="customerContactValidation"
                                class="text-danger display-block"
                              >
                                {{ customerContactError }}
                              </small>
                            </validation-provider>
                            <b-button
                              v-if="!existsMultipleCustomer"
                              variant="link"
                              class="p4a__text-base-color phone-email-button text-left"
                              @click="isPhoneHidden = true; isEmailHidden = false; customerContact = ''"
                            >
                              Not from Singapore? Use email instead
                            </b-button>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row v-if="!isEmailHidden">
                      <b-col lg="12">
                        <div class="form-row form-group">
                          <label class="col-md-3 col-form-label p4a__text-bold-gray">Email address *</label>
                          <div class="col">
                            <validation-provider
                              #default="{ }"
                              name="Email Address"
                              vid="h-customer-email"
                              rules="required|email"
                            >
                              <b-form-input
                                id="h-customer-email"
                                v-model="customerEmail"
                                class="p4a__activity-input"
                                placeholder="email@domain.com"
                                :state="(customerEmailValidation) ? false : null"
                                name="customerEmail"
                                :disabled="existsMultipleCustomer"
                                @input="customerEmailValidation = false"
                              />
                              <!-- <small class="text-danger display-block">{{ errors[0] }}</small> -->
                              <small
                                v-if="customerEmailValidation"
                                class="text-danger display-block"
                              >
                                {{ customerEmailError }}
                              </small>
                            </validation-provider>
                            <b-button
                              v-if="!existsMultipleCustomer"
                              variant="link"
                              class="p4a__text-base-color phone-email-button text-left"
                              @click="isPhoneHidden = false; isEmailHidden = true; customerEmail = ''"
                            >
                              From Singapore? Use mobile number instead
                            </b-button>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row v-if="existsMultipleCustomer">
                      <b-col lg="12">
                        <div class="form-row form-group">
                          <label class="col-md-8 col-form-label p4a__text-bold-gray">Please enter the last 4 characters of your NRIC *</label>
                          <div class="col">
                            <validation-provider
                              #default="{ }"
                              name="NRIC"
                              vid="h-customer-nric"
                              rules="required"
                            >
                              <b-form-input
                                id="h-customer-nric"
                                v-model="customerNric"
                                class="p4a__activity-input"
                                placeholder="E.g. 123A"
                                :state="(customerNricValidation) ? false : null"
                                name="customerNric"
                                @input="customerNricValidation = false"
                              />
                              <!-- <small class="text-danger display-block">{{ errors[0] }}</small> -->
                              <small
                                v-if="customerNricValidation"
                                class="text-danger display-block"
                              >
                                {{ customerNricError }}
                              </small>
                            </validation-provider>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <b-button
                      type="submit"
                      variant="front"
                      class="p4a__contact-form__btn"
                      :class="isMobile() ? 'w-100' : 'w-50'"
                      :disabled="invalid || customerSubmitButtonDisabled"
                    >
                      Continue
                    </b-button>
                  </validation-observer>
                </b-form>
              </div>
              <div
                v-if="isAgent"
                class="p4a__contact-new-customer"
              >
                <p class="p4a__contact-customer__text">
                  Please enter your 4 or 5 digits agent code below to get started:
                </p>
                <b-form @submit.prevent="submitAgentVerification">
                  <validation-observer
                    ref="frontAgentVerificationForm"
                    #default="{invalid}"
                  >
                    <b-row>
                      <b-col lg="12">
                        <div class="form-row form-group">
                          <label class="col-md-3 col-form-label p4a__text-bold-gray">Agent Code *</label>
                          <div class="col">
                            <validation-provider
                              #default="{ }"
                              name="Agent Code"
                              vid="h-agent-number"
                              rules="required"
                            >
                              <b-form-input
                                id="h-agent-number"
                                v-model="agentNumber"
                                class="p4a__activity-input p4a__agent-code-input"
                                placeholder="E.g. 42042"
                                :state="(agentNumberValidation) ? false : null"
                                name="Agent Code"
                              />
                              <!-- <small class="text-danger display-block">{{ errors[0] }}</small> -->
                              <small
                                v-if="agentNumberValidation"
                                class="text-danger display-block"
                              >
                                {{ agentNumberError }}
                              </small>
                            </validation-provider>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <b-button
                      type="submit"
                      variant="front"
                      class="p4a__contact-form__btn w-50"
                      :disabled="invalid || agentSubmitButtonDisabled"
                    >
                      Continue
                    </b-button>
                  </validation-observer>
                </b-form>
              </div>
            </div>
          </b-card>

          <b-card
            v-if="otpFormCard"
            class="p4a__contact-card"
          >
            <b-card-text v-if="isEmailHidden">
              <h3 class="p4a__contact-card__title">
                Mobile number verification
              </h3>
              <p class="p4a__contact-card__sub-title">
                We’ve sent the OTP number to <strong>{{ customerContact.slice(0, 3) + customerContact.slice(3).replace(/.(?=.{4,}$)/g, 'x') }}</strong>
              </p>
            </b-card-text>
            <b-card-text v-else>
              <h3 class="p4a__contact-card__title">
                Email address verification
              </h3>
              <p class="p4a__contact-card__sub-title">
                We’ve sent the OTP number to <strong>{{ customerEmail }}</strong>
              </p>
            </b-card-text>
            <div class="p4a__contact-form otp-form">
              <div class="p4a__contact-new-customer">
                <b-form @submit.prevent="submitOtpForm">
                  <validation-observer
                    ref="otpForm"
                    #default="{invalid}"
                  >
                    <div
                      class="d-flex justify-content-between"
                      style="gap: 10px"
                    >
                      <div
                        v-for="i in totalOtpInput"
                        :key="i"
                      >
                        <validation-provider
                          name="OTP"
                          :vid="'h-otp-number' + i"
                          rules="required"
                        >
                          <b-form-input
                            :id="'h-otp-number' + i"
                            v-model="otpCodes[i - 1]"
                            class="p4a__otp-input"
                            type="text"
                            maxlength="1"
                            @keyup="handleKeyUp($event, i)"
                            @paste="handlePaste($event, i)"
                          />
                        </validation-provider>
                      </div>
                    </div>
                    <div
                      v-if="timeCountDown > 0"
                      class="p4a__text-block"
                    >
                      <p>Didn’t receive an OTP?</p>
                      <p>You can request a new code in {{ timeCountDown }}</p>
                      <p
                        class="text-front cursor-pointer"
                        @click="changeOtpNumber"
                      >
                        <u>Use a different {{ isEmailHidden ? 'mobile number' : 'email address' }}</u>
                      </p>
                    </div>
                    <div
                      v-else
                      class="p4a__text-block"
                    >
                      <p>Didn’t receive an OTP?</p>
                      <p
                        class="text-front cursor-pointer"
                        @click="sendConfirmationOTP"
                      >
                        <u>Request a new code</u>
                      </p>
                      <p
                        class="text-front cursor-pointer"
                        @click="changeOtpNumber"
                      >
                        <u>Use a different {{ isEmailHidden ? 'mobile number' : 'email address' }}</u>
                      </p>
                    </div>
                    <b-button
                      type="submit"
                      variant="front"
                      height="58"
                      class="p4a__contact-form__btn w-100"
                      :disabled="invalid"
                    >
                      Verify
                    </b-button>
                  </validation-observer>
                </b-form>
              </div>
            </div>
          </b-card>

          <b-card
            v-if="agentOtpFormCard"
            class="p4a__contact-card"
          >
            <b-card-text>
              <h3 class="p4a__contact-card__title">
                Mobile number verification
              </h3>
              <p class="p4a__contact-card__sub-title">
                We’ve sent the OTP number to <strong>{{ agentContact.slice(0, 3) + agentContact.slice(3).replace(/.(?=.{4,}$)/g, 'x') }}</strong>
              </p>
            </b-card-text>
            <div class="p4a__contact-form otp-form">
              <div class="p4a__contact-new-customer">
                <b-form @submit.prevent="submitOtpForm">
                  <validation-observer
                    ref="otpForm"
                    #default="{invalid}"
                  >
                    <div
                      class="d-flex justify-content-between"
                      style="gap: 10px"
                    >
                      <div
                        v-for="i in totalOtpInput"
                        :key="i"
                      >
                        <validation-provider
                          name="OTP"
                          :vid="'h-otp-number' + i"
                          rules="required"
                        >
                          <b-form-input
                            :id="'h-otp-number' + i"
                            v-model="otpCodes[i - 1]"
                            class="p4a__otp-input"
                            type="text"
                            maxlength="1"
                            @keyup="handleKeyUp($event, i)"
                            @paste="handlePaste($event, i)"
                          />
                        </validation-provider>
                      </div>
                    </div>
                    <div
                      v-if="timeCountDown > 0"
                      class="p4a__text-block"
                    >
                      <p>Didn’t receive an OTP?</p>
                      <p>You can request a new code in {{ timeCountDown }}</p>
                      <p
                        class="text-front cursor-pointer"
                        @click="changeOtpNumber"
                      >
                        Use a different {{ isEmailHidden ? 'mobile number' : 'email address' }}
                      </p>
                    </div>
                    <div
                      v-else
                      class="p4a__text-block"
                    >
                      <p>Didn’t receive an OTP?</p>
                      <p
                        class="text-front cursor-pointer"
                        @click="sendConfirmationOTP"
                      >
                        Request a new code
                      </p>
                      <p
                        class="text-front cursor-pointer"
                        @click="changeOtpNumber"
                      >
                        Use a different {{ isEmailHidden ? 'mobile number' : 'email address' }}
                      </p>
                    </div>
                    <b-button
                      type="submit"
                      variant="front"
                      class="p4a__contact-form__btn w-100"
                      :disabled="invalid"
                    >
                      Verify
                    </b-button>
                  </validation-observer>
                </b-form>
              </div>
            </div>
          </b-card>
        </b-container>
      </div>
    </div>
    <b-modal
      id="new-customer-confirmation"
      ref="new_customer_confirmation"
      size="lg"
      title=""
      centered
      no-stacking
      hide-footer
      modal-class="p4a__otp-confirm-modal"
      header-class="front-modal"
      @close="customerSubmitButtonDisabled = false"
    >
      <div v-if="isEmailHidden">
        <div class="p4a__otp-modal-content">
          <div class="p4a__otp-modal-title">
            <h3>Confirmation Required</h3>
          </div>
          <div class="p4a__otp-modal-subtitle">
            <p>We will now send a one time password to applicant’s mobile <span>{{ customerContact }}</span></p>
            <p>to confirm your registration.</p>
            <p>为了确认成功报名注册，我们将会寄出一次性密码至申请人号码 <span>{{ customerContact }}</span></p>
          </div>
        </div>
        <b-button
          variant="front"
          class="p4a__otp-modal-btn"
          @click="sendConfirmationOTP()"
        >
          Send OTP to {{ customerContact }} 一次性密码
        </b-button>
      </div>
      <div v-else>
        <div class="p4a__otp-modal-content">
          <div class="p4a__otp-modal-title">
            <h3>Confirmation Required</h3>
          </div>
          <div class="p4a__otp-modal-subtitle">
            <p>We will now send a one time password to applicant’s email <span>{{ customerEmail }}</span></p>
            <p>to confirm your registration.</p>
            <p>为了确认成功报名注册，我们将会寄出一次性密码至申请人邮箱 <span>{{ customerEmail }}</span></p>
          </div>
        </div>
        <b-button
          variant="front"
          class="p4a__otp-modal-btn"
          @click="sendConfirmationOTP()"
        >
          Send OTP to {{ customerEmail }} 一次性密码
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
/* eslint-disable global-require */
import {
  BRow, BCol, BContainer, BCard, BCardText, BImg, BButton, BForm, BFormInput,
} from 'bootstrap-vue'
// import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { required } from '@validations'
// import HorizontalScroll from 'vue-horizontal-scroll'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-horizontal-scroll/dist/vue-horizontal-scroll.css'
import Loader from './Loader.vue'

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BCard,
    BCardText,
    BImg,
    BButton,
    BForm,
    BFormInput,

    // Cleave,
    Loader,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      logoImg: require('@/assets/images/frontend/logo-orange-yellow-svg.svg'),
      logoMobileImg: require('@/assets/images/frontend/logo-orange-yellow-mobile.svg'),
      personAddIcon: require('@/assets/images/frontend/icons/person_add.svg'),
      patientListIcon: require('@/assets/images/frontend/icons/patient_list.svg'),
      badgeIcon: require('@/assets/images/frontend/icons/badge.svg'),

      contactCard: true,
      otpFormCard: false,
      agentOtpFormCard: false,
      isPhoneHidden: false,
      isEmailHidden: true,
      isNewCustomer: false,
      isExistingCustomer: false,
      isAgent: false,
      existsMultipleCustomer: false,
      customerSubmitButtonDisabled: false,
      agentSubmitButtonDisabled: false,
      customerContact: '+65 ',
      customerContactValidation: false,
      customerContactError: 'The Mobile Number field is required',
      customerEmail: '',
      customerEmailValidation: false,
      customerEmailError: 'Valid email is required',
      customerNric: '',
      customerNricValidation: false,
      customerNricError: 'Valid NRIC is required',
      agentContact: '',
      agentContactOriginal: '',
      agentNumber: '',
      agentNumberValidation: false,
      agentNumberError: 'This agent code is invalid',
      existingCustomers: [],
      nirvanaContact: '',

      // OTP
      timeCountDown: 0,
      totalOtpInput: 4,
      otpCodes: ['', '', '', ''],
      activityType: {},
      options: {
        prefix: {
          numericOnly: true,
          prefix: '+65',
          blocks: [3, 8],
        },
      },
      required,
    }
  },

  beforeMount() {
    this.$http.get(`front-end/activity/${this.$route.params.id}/show`)
      .then(response => {
        this.activityType = response.data.data || {}
        this.nirvanaContact = response.data.nirvanaContact
      })
  },

  methods: {
    openBottomForm(type) {
      this.existsMultipleCustomer = false
      if (type === 'new') {
        if (this.activityType.prospectBookable === true) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'The selected event is for existing customers only',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } else {
          this.isNewCustomer = true
          this.isExistingCustomer = false
          this.isAgent = false
        }
      } else if (type === 'existing') {
        this.isNewCustomer = false
        this.isExistingCustomer = true
        this.isAgent = false
      } else {
        this.isNewCustomer = false
        this.isExistingCustomer = false
        this.isAgent = true
      }
    },
    validateEmail(inputText) {
      // eslint-disable-next-line no-useless-escape
      const mailformat = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      if (inputText.match(mailformat)) {
        return true
      }
      return false
    },

    validateCustomerName() {
      this.customerNameValidation = false
      this.enableNextPhaseCustomerDetail()
    },

    validateCustomerContact() {
      if (this.customerContact.length === 12) {
        this.customerContactValidation = false
        this.enableNextPhaseCustomerDetail()
        this.checkForCustomerSchedule()
      } else {
        this.customerContactValidation = true
        this.enableNextPhaseCustomer = false
      }
    },

    submitCustomerVerification() {
      let valid = false
      if (this.isPhoneHidden) {
        if (this.validateEmail(this.customerEmail)) {
          valid = true
        } else {
          this.customerEmailValidation = true
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (this.customerContact.length) {
          valid = true
        } else {
          this.customerContactValidation = true
        }
      }

      if (valid) {
        this.customerSubmitButtonDisabled = true
        if (this.isNewCustomer) {
          this.$refs.new_customer_confirmation.show()
        } else {
          // eslint-disable-next-line no-lonely-if
          if (this.existsMultipleCustomer) {
            if (this.customerNric.length === 4) {
              const found = this.existingCustomers.find(c => c.nric.slice(-4) === this.customerNric)
              if (found) {
                localStorage.setItem('verifiedCustomer', JSON.stringify(found))
                this.sendExistingCustomerConfirmationOTP(found)
              } else {
                // this.customerNricError = 'NRIC does not match'
                // this.customerNricValidation = true
                // this.customerSubmitButtonDisabled = false
                this.$swal({
                  title: '<span class="p4a__swal-danger-title">Error: We couldn’t find your account</span>',
                  html: `<p class="p4a__swal-text">For assistance with your appointment booking, please contact our team at <strong><a href="tel:${this.nirvanaContact}">${this.nirvanaContact}</a></strong></p>`,
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/alert.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  allowOutsideClick: false,
                  reverseButtons: true,
                  showCancelButton: true,
                  showDenyButton: true,
                  denyButtonText: 'Try again',
                  confirmButtonText: 'Book without customer account',
                  cancelButtonText: 'Back to home',
                  customClass: {
                    confirmButton: 'btn p4a__phone-email-btn',
                    cancelButton: 'btn p4a__swal-outline-btn',
                    denyButton: 'btn ml-1 btn-front p4a__swal-common-btn',
                  },
                  buttonsStyling: false,
                  width: '600px',
                })
                  .then(result => {
                    this.customerSubmitButtonDisabled = false
                    if (result.isConfirmed) {
                      this.changeOtpNumber()
                      this.openBottomForm('new')
                    } else if (result.isDenied) {
                      this.changeOtpNumber()
                    } else {
                      this.$router.push({ name: 'appointments-front-end' })
                    }
                  })
              }
            } else {
              this.customerNricError = 'Please Enter the last 4 digits of your NRIC'
              this.customerNricValidation = true
              this.customerSubmitButtonDisabled = false
            }
          } else {
            this.verifyExistingCustomer()
          }
        }
      }
    },

    submitAgentVerification() {
      if (this.agentNumber.length === 4 || this.agentNumber.length === 5) {
        this.agentSubmitButtonDisabled = true
        this.$http
          .post('front-end/bookings/send-otp/frontend/agent', { agentNumber: this.agentNumber })
          .then(response => {
            if (response.data.exists) {
              localStorage.setItem('bookingAgent', JSON.stringify(response.data.agent))
              localStorage.setItem('agentCustomers', JSON.stringify(response.data.customers))
              this.agentContact = response.data.agent.personalContact
              this.agentContactOriginal = response.data.agent.personalContact
              this.timeCountDown = 60
              this.contactCard = false
              this.agentOtpFormCard = true
              this.agentSubmitButtonDisabled = false
              this.countDownTimer()
              if (response.data.alert) {
              // eslint-disable-next-line no-alert
                alert(response.data.alert)
              }
            } else {
              this.agentSubmitButtonDisabled = false
              this.agentNumberError = 'This agent code is invalid'
              this.agentNumberValidation = true
            }
          })
          .catch(error => {
            this.agentSubmitButtonDisabled = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        this.agentNumberError = 'This agent code is invalid'
        this.agentNumberValidation = true
      }
    },

    submitOtpForm() {
      let otpSentToPhone = ''
      let contactType = ''
      if (this.isEmailHidden) {
        otpSentToPhone = this.customerContact
        contactType = 'phone'
      } else {
        otpSentToPhone = this.customerEmail
        contactType = 'email'
      }
      const otp = this.otpCodes.join('')
      this.otpCodes = ['', '', '', '']
      if (this.isNewCustomer) {
        this.submitOtpNewCustomer(otpSentToPhone, otp, contactType)
      } else if (this.isExistingCustomer) {
        this.submitOtpExistingCustomer(otpSentToPhone, otp, contactType)
      } else {
        this.submitAgentOtp(otp)
      }
    },

    submitOtpNewCustomer(otpSentToPhone, otp, contactType) {
      this.$http
        .post('front-end/bookings/submit-otp/new-customer', { otpSentToPhone, otp, contactType })
        .then(response => {
          if (!response.data.exists || !response.data.single) {
            localStorage.setItem('verifiedCustomerType', 'new')
            localStorage.setItem('customerVerifiedContactType', contactType)
            localStorage.setItem('customerVerifiedContact', otpSentToPhone)
            localStorage.removeItem('bookingAgent')
            localStorage.removeItem('verifiedCustomer')
            this.$router.push({ name: 'activity-booking-frontend', params: { id: this.$route.params.id } })
          } else {
            let cancelButtonText = 'Use a different mobile number'
            let contactTypeText = 'mobile number'
            if (contactType === 'email') {
              cancelButtonText = 'Use a different email address'
              contactTypeText = 'email address'
            }
            this.$swal({
              title: `<span class="p4a__swal-warning-title">Are you booking as “${response.data.customer.name}”?</span>`,
              html: `<p class="p4a__swal-text">Our records show that this ${contactTypeText} is registered under an existing Nirvana customer.
                 We’ll continue to register this booking under ${response.data.customer.name}.</p>
                <p class="p4a__swal-text">If you believe this is an error, please contact our team at <strong><a href="tel:${this.nirvanaContact}">${this.nirvanaContact}</a></strong></p>`,
              // eslint-disable-next-line global-require
              imageUrl: require('@/assets/images/icons/warning.png'),
              imageWidth: 80,
              imageHeight: 80,
              imageAlt: 'Custom Icon',
              reverseButtons: false,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonText: 'Yes, continue',
              cancelButtonText,
              customClass: {
                confirmButton: 'btn btn-front p4a__swal-common-btn mb-1 mr-2',
                cancelButton: 'btn p4a__phone-email-btn',
              },
              buttonsStyling: false,
            })
              .then(result => {
                if (result.value) {
                  localStorage.setItem('verifiedCustomerType', 'existing')
                  localStorage.setItem('customerVerifiedContactType', contactType)
                  localStorage.setItem('customerVerifiedContact', otpSentToPhone)
                  localStorage.setItem('verifiedCustomer', JSON.stringify(response.data.customer))
                  localStorage.removeItem('bookingAgent')
                  this.$router.push({ name: 'activity-booking-frontend', params: { id: this.$route.params.id } })
                } else {
                  this.changeOtpNumber()
                }
              })
          }
        })
        .catch(error => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: error.data.message || '',
          //     icon: 'AlertTriangleIcon',
          //     variant: 'danger',
          //   },
          // })
          let titleText = 'Verification failed'
          if (error.data && error.data.notFound) {
            titleText = 'Incorrect OTP'
          }
          this.$swal({
            title: `<span class="p4a__swal-danger-title">${titleText}</span>`,
            html: `<p class="p4a__swal-text mb-0">We were unable to verify your ${contactType === 'email' ? 'email address' : 'number'}</p><p class="mb-0 p4a__swal-text"><strong>${otpSentToPhone}</strong>. Please try again</p>`,
            // eslint-disable-next-line global-require
            imageUrl: require('@/assets/images/icons/cross.png'),
            imageWidth: 80,
            imageHeight: 80,
            imageAlt: 'Custom Icon',
            reverseButtons: true,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonText: 'Try again',
            cancelButtonText: 'Back to home',
            customClass: {
              confirmButton: 'btn btn-front p4a__swal-common-btn ml-1',
              cancelButton: 'btn p4a__swal-outline-btn',
            },
            buttonsStyling: false,
            width: '500px',
          })
            .then(result => {
              if (result.value) {
                this.changeOtpNumber()
              } else {
                this.$router.push({ name: 'appointments-front-end' })
              }
            })
        })
    },

    submitAgentOtp(otp) {
      this.$http
        .post('front-end/bookings/submit-otp/existing-customer', { otpSentToPhone: this.agentContactOriginal, otp })
        .then(response => {
          if (response.data.success) {
            localStorage.setItem('verifiedCustomerType', 'agent')
            localStorage.removeItem('verifiedCustomer')
            localStorage.removeItem('customerVerifiedContactType')
            localStorage.removeItem('customerVerifiedContact')
            this.$router.push({ name: 'activity-booking-frontend', params: { id: this.$route.params.id } })
          } else {
            this.$swal({
              title: '<span class="p4a__swal-danger-title">Incorrect OTP</span>',
              html: `<p class="mb-0 p4a__swal-text">We were unable to verify your number</p><p class="mb-0 p4a__swal-text"><strong>${this.agentContact}</strong>. Please try again</p>`,
              // eslint-disable-next-line global-require
              imageUrl: require('@/assets/images/icons/cross.png'),
              imageWidth: 80,
              imageHeight: 80,
              imageAlt: 'Custom Icon',
              reverseButtons: true,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonText: 'Try again',
              cancelButtonText: 'Back to home',
              customClass: {
                confirmButton: 'btn btn-front p4a__swal-common-btn ml-1',
                cancelButton: 'btn p4a__swal-outline-btn',
              },
              buttonsStyling: false,
              width: '500px',
            })
              .then(result => {
                if (result.value) {
                  this.changeOtpNumber()
                } else {
                  this.$router.push({ name: 'appointments-front-end' })
                }
              })
          }
        })
        .catch(() => {
          this.$swal({
            title: '<span class="p4a__swal-danger-title">Verification failed</span>',
            html: `<p class="p4a__swal-text">For assistance with your appointment booking, please contact our team at <strong><a href="tel:${this.nirvanaContact}">${this.nirvanaContact}</a></strong></p>.`,
            // eslint-disable-next-line global-require
            imageUrl: require('@/assets/images/icons/cross.png'),
            imageWidth: 80,
            imageHeight: 80,
            imageAlt: 'Custom Icon',
            reverseButtons: true,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonText: 'Try again',
            cancelButtonText: 'Back to home',
            customClass: {
              confirmButton: 'btn btn-front p4a__swal-common-btn ml-1',
              cancelButton: 'btn btn p4a__swal-outline-btn',
            },
            buttonsStyling: false,
          })
            .then(result => {
              if (result.value) {
                this.changeOtpNumber()
              } else {
                this.$router.push({ name: 'appointments-front-end' })
              }
            })
        })
    },

    submitOtpExistingCustomer(otpSentToPhone, otp, contactType) {
      this.$http
        .post('front-end/bookings/submit-otp/existing-customer', { otpSentToPhone, otp, contactType })
        .then(response => {
          if (response.data.success) {
            localStorage.setItem('verifiedCustomerType', 'existing')
            localStorage.setItem('customerVerifiedContactType', contactType)
            localStorage.setItem('customerVerifiedContact', otpSentToPhone)
            localStorage.removeItem('bookingAgent')
            this.$router.push({ name: 'activity-booking-frontend', params: { id: this.$route.params.id } })
          } else {
            this.$swal({
              title: '<span class="p4a__swal-danger-title">Incorrect OTP</span>',
              html: `<p class="mb-0 p4a__swal-text">We were unable to verify your ${contactType === 'email' ? 'email address' : 'number'}</p><p class="mb-0 p4a__swal-text"><strong>${otpSentToPhone}</strong>. Please try again</p>`,
              // eslint-disable-next-line global-require
              imageUrl: require('@/assets/images/icons/cross.png'),
              imageWidth: 80,
              imageHeight: 80,
              imageAlt: 'Custom Icon',
              reverseButtons: true,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonText: 'Try again',
              cancelButtonText: 'Back to home',
              customClass: {
                confirmButton: 'btn btn-front p4a__swal-common-btn ml-1',
                cancelButton: 'btn p4a__swal-outline-btn',
              },
              buttonsStyling: false,
              width: '500px',
            })
              .then(result => {
                if (result.value) {
                  this.changeOtpNumber()
                } else {
                  this.$router.push({ name: 'appointments-front-end' })
                }
              })
          }
        })
        .catch(() => {
          this.$swal({
            title: '<span class="p4a__swal-danger-title">Verification failed</span>',
            html: `<p class="p4a__swal-text">For assistance with your appointment booking, please contact our team at <strong><a href="tel:${this.nirvanaContact}">${this.nirvanaContact}</a></strong></p>.`,
            // eslint-disable-next-line global-require
            imageUrl: require('@/assets/images/icons/cross.png'),
            imageWidth: 80,
            imageHeight: 80,
            imageAlt: 'Custom Icon',
            reverseButtons: true,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonText: 'Try again',
            cancelButtonText: 'Back to home',
            customClass: {
              confirmButton: 'btn btn-front p4a__swal-common-btn ml-1',
              cancelButton: 'btn p4a__swal-outline-btn',
            },
            buttonsStyling: false,
          })
            .then(result => {
              if (result.value) {
                this.changeOtpNumber()
              } else {
                this.$router.push({ name: 'appointments-front-end' })
              }
            })
        })
    },

    changeOtpNumber() {
      this.existsMultipleCustomer = false
      this.contactCard = true
      this.otpFormCard = false
      this.agentOtpFormCard = false
    },

    countDownTimer() {
      if (this.timeCountDown > 0) {
        setTimeout(() => {
          this.timeCountDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    },

    verifyExistingCustomer() {
      let otpSentToPhone = ''
      let contactType = ''
      if (this.isEmailHidden) {
        otpSentToPhone = this.customerContact
        contactType = 'phone'
      } else {
        otpSentToPhone = this.customerEmail
        contactType = 'email'
      }
      this.$http
        .post('front-end/bookings/existing-customer/verify', { otpSentToPhone, contactType })
        .then(response => {
          this.nirvanaContact = response.data.nirvanaContact
          if (response.data.exists) {
            if (response.data.single) {
              localStorage.setItem('verifiedCustomer', JSON.stringify(response.data.customer[0]))
              this.sendExistingCustomerConfirmationOTP(response.data.customer[0])
            } else {
              this.existingCustomers = response.data.customer
              this.existsMultipleCustomer = true
              this.customerSubmitButtonDisabled = false
            }
          } else {
            this.$swal({
              title: '<span class="p4a__swal-danger-title">Error: We couldn’t find your account</span>',
              html: `<p class="p4a__swal-text">For assistance with your appointment booking, please contact our team at <strong><a href="tel:${this.nirvanaContact}">${this.nirvanaContact}</a></strong></p>`,
              // eslint-disable-next-line global-require
              imageUrl: require('@/assets/images/icons/alert.png'),
              imageWidth: 80,
              imageHeight: 80,
              imageAlt: 'Custom Icon',
              allowOutsideClick: false,
              reverseButtons: true,
              showCancelButton: true,
              showDenyButton: true,
              denyButtonText: 'Try again',
              confirmButtonText: 'Book without customer account',
              cancelButtonText: 'Back to home',
              customClass: {
                confirmButton: 'btn p4a__phone-email-btn',
                cancelButton: 'btn p4a__swal-outline-btn',
                denyButton: 'btn ml-1 btn-front p4a__swal-common-btn',
              },
              buttonsStyling: false,
              width: '600px',
            })
              .then(result => {
                this.customerSubmitButtonDisabled = false
                if (result.isConfirmed) {
                  this.changeOtpNumber()
                  this.openBottomForm('new')
                } else if (result.isDenied) {
                  this.changeOtpNumber()
                } else {
                  this.$router.push({ name: 'appointments-front-end' })
                }
              })
          }
        })
        .catch(error => {
          this.customerSubmitButtonDisabled = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    sendExistingCustomerConfirmationOTP(customer) {
      let otpSentToPhone = ''
      if (this.isEmailHidden) {
        otpSentToPhone = this.customerContact
      } else {
        otpSentToPhone = this.customerEmail
      }
      this.$http
        .post('front-end/bookings/send-otp/frontend', { otpSentToPhone, customerName: customer.name })
        .then(response => {
          this.timeCountDown = 60
          this.contactCard = false
          this.otpFormCard = true
          this.customerSubmitButtonDisabled = false
          this.countDownTimer()
          if (response.data.alert) {
            // eslint-disable-next-line no-alert
            alert(response.data.alert)
          }
        })
        .catch(error => {
          this.customerSubmitButtonDisabled = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    sendConfirmationOTP() {
      let otpSentToPhone = ''
      let customerName = ''
      if (this.isAgent) {
        customerName = 'Agent'
        otpSentToPhone = this.agentContactOriginal
      } else {
        customerName = 'Customer'
        if (this.isEmailHidden) {
          otpSentToPhone = this.customerContact
        } else {
          otpSentToPhone = this.customerEmail
        }
      }
      this.$http
        .post('front-end/bookings/send-otp/frontend', { otpSentToPhone, customerName })
        .then(response => {
          this.timeCountDown = 60
          this.$refs.new_customer_confirmation.hide()
          this.contactCard = false
          if (this.isAgent) {
            this.agentOtpFormCard = true
          } else {
            this.otpFormCard = true
          }
          this.customerSubmitButtonDisabled = false
          this.countDownTimer()
          if (response.data.alert) {
            // eslint-disable-next-line no-alert
            alert(response.data.alert)
          }
        })
        .catch(error => {
          this.customerSubmitButtonDisabled = false
          this.$refs.new_customer_confirmation.hide()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    validateCustomerEmail() {
      if (this.validateEmail(this.customerEmail)) {
        this.customerEmailValidation = false
        this.enableNextPhaseCustomerDetail()
        this.checkForCustomerSchedule()
      } else {
        this.customerEmailValidation = true
        this.enableNextPhaseCustomer = false
      }
    },

    async handleKeyUp(event, index) {
      // event.preventDefault()
      if (event.code !== 'Tab' && event.code !== 'ArrowRight' && event.code !== 'ArrowLeft' && event.code !== 'MetaLeft' && event.code !== 'KeyV') {
        event.preventDefault()
      }
      if (event.code === 'Backspace') {
        this.otpCodes[index - 1] = ''
      }

      // if (this.$refs.otpForm !== null && index > 1) {
      //   const inputEl = this.$refs.otpForm.$children[index - 2].$children[0].$el
      //   if (inputEl) inputEl.focus()
      // }

      const numberRegExp = /^([0-9])$/

      if (numberRegExp.test(event.key)) {
        this.otpCodes[index - 1] = event.key
        if (this.$refs.otpForm !== null && index < this.$refs.otpForm.$children.length) {
          this.$refs.otpForm.$children[index - 1].$children[0].$el.blur()
          const inputEl = this.$refs.otpForm.$children[index].$children[0].$el
          if (inputEl) inputEl.focus()
        } else if (this.$refs.otpForm !== null && index === this.$refs.otpForm.$children.length) {
          this.$refs.otpForm.$children[index - 1].$children[0].$el.blur()
        }
      }
    },

    handlePaste(e, index) {
      const pasteData = (e.originalEvent || e).clipboardData.getData('text/plain')
      let pastDataLength = 0
      if (pasteData.length > 5) {
        pastDataLength = 4
      } else {
        pastDataLength = pasteData.length
      }

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < pastDataLength; i++) {
        this.otpCodes[i] = pasteData[i]
      }

      this.$refs.otpForm.$children[index - 1].$children[0].$el.blur()
    },
  },
}
</script>

<style lang="scss">
body{
  background-color: #f5f0df!important
}
</style>
